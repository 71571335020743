import React, { useContext } from 'react';

import { StoreContext } from '../context';
import { InfoDialog } from '.';

import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Link,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { AddBox, DeleteForever } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
  },
  submit: {
    marginTop: theme.spacing(1),
  },
  marg: {
    margin: theme.spacing(1),
  },
  formControl: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
    marginRight: theme.spacing(),
    width: '99%',
  },
  mountingAngle: {
    margin: theme.spacing(1),
  },
  btns: {
    display: 'flex',
    flexDirection: 'row',
  },
  splitCol: {
    display: 'flex',
  },
  screenSideIndicator: {
    maxWidth: '65%',
  },
  headerBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'rowReverse',
    gap: '16px',
  },
  titleBox: {
    flexGrow: 1,
  },
}));

const DrawBeams = ({ rf }) => {
  const classes = useStyles();
  const ctx = useContext(StoreContext);
  const { beamMs } = rf;

  const deleteBeamHandler = (e, i) => {
    e.preventDefault();
    const newBeamMs = [...beamMs];
    newBeamMs.splice(i, 1);
    ctx.setRf({
      ...ctx.rf,
      beamMs: newBeamMs,
    });
  };

  return (
    beamMs?.length > 0 &&
    beamMs.map((beamM, i) => (
      <Grid container item xs={12} spacing={1} key={i}>
        <Grid item xs={5}>
          <FormControl className={classes.formControl} required fullWidth>
            <InputLabel shrink>Beam Type</InputLabel>
            <Select
              name='beamType'
              required
              style={{ paddingTop: 5 }}
              value={beamM.beamType}
              onChange={(e) => ctx.handleChangeBeamM(e, i)}
            >
              <MenuItem value={'Midspan 2x8'}>Midspan 2x8</MenuItem>
              <MenuItem value={'Midspan 4x8'}>Midspan 4x8</MenuItem>
              <MenuItem value={'Midspan 2 Ply 2x8'}>2 Ply Midspan 2x8</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl className={classes.formControl} required fullWidth>
            <TextField
              name='beamFeet'
              style={{ paddingTop: 5 }}
              required
              value={beamM.beamFeet}
              onFocus={(e) => e.target.select()}
              onChange={(e) => ctx.handleChangeBeamM(e, i)}
              type='number'
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: 0,
              }}
              label='Feet'
            />
          </FormControl>
        </Grid>
        <Grid container item xs={4}>
          <Box key={i} display='flex'>
            <FormControl className={classes.formControl} required fullWidth>
              <TextField
                name='beamInches'
                style={{ paddingTop: 5 }}
                required
                value={beamM.beamInches}
                onFocus={(e) => e.target.select()}
                onChange={(e) => ctx.handleChangeBeamM(e, i)}
                type='number'
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: 0,
                  max: 11.99,
                }}
                label='Inches'
              />
            </FormControl>
            <Link
              onClick={(e) => deleteBeamHandler(e, i)}
              size='small'
              color='error'
              component='button'
              variant='body2'
            >
              <DeleteForever fontSize='small' />
            </Link>
          </Box>
        </Grid>
      </Grid>
    ))
  );
};

const StoreRoofsBeamMs = ({ rf }) => {
  const ctx = useContext(StoreContext);
  const { beamMs } = rf;

  return (
    <Grid
      container
      item
      xs={12}
      style={{
        alignItems: 'center',
        marginBottom: '5px',
      }}
    >
      <Grid item container xs={12} style={{ paddingTop: '10px' }}>
        <Grid item xs={'auto'}>
          <Typography>
            <strong>Midspan Beams</strong>
          </Typography>
        </Grid>
        <Grid item>
          <InfoDialog type={'Midspan Beams'} />
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Button
          variant='contained'
          color='primary'
          size='small'
          onClick={(e) =>
            ctx.setRf({
              ...ctx.rf,
              beamMs: ctx.handleAddBeamMsToRoof(beamMs),
            })
          }
        >
          <AddBox style={{ paddingRight: 2 }} /> Add
        </Button>
      </Grid>
      <Grid item xs={8}>
        <Typography variant='subtitle2'>
          {`${beamMs?.length} Beam${beamMs?.length === 1 ? '' : 's'}`}
        </Typography>
      </Grid>
      {beamMs?.length > 0 && <DrawBeams rf={ctx.rf} />}
    </Grid>
  );
};

export { StoreRoofsBeamMs };
