import axios from 'axios';
import _ from 'lodash';
import { toast } from 'react-toastify';

import { scrKitMapper, rfKitMapper } from '../utils/kitMapper';

import {
  BC_LIST_ITEMS_REQUEST,
  BC_LIST_ITEMS_SUCCESS,
  BC_LIST_ITEMS_FAIL,
  BC_SALES_QUOTE_REQUEST,
  BC_SALES_QUOTE_SUCCESS,
  BC_SALES_QUOTE_FAIL,
} from '../constants';

export const bcItemsList = (pg) => async (dispatch, getState) => {
  try {
    dispatch({
      type: BC_LIST_ITEMS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const cpg = pg ? pg : userInfo.bc_customer_priceGroup;

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
      proxy: {
        host: 'localhost',
        port: 5000,
      },
    };
    const { data } = await axios.get('/api/bc/items', config);
    const { data: prices } = await axios.get(`/api/bc/prices/${cpg}`, config);

    // console.log('prices', prices);
    // console.log('data', data);

    const applyCustomerTierRate = (price) => {
      const customerTierRate = userInfo?.bc_customer_tierRate;
      let calcedPrice = price;
      if (
        customerTierRate !== undefined &&
        customerTierRate !== null &&
        customerTierRate > 0
      ) {
        calcedPrice = price * customerTierRate;
        // console.log('calcedPrice', calcedPrice);
        return calcedPrice;
      } else {
        return price;
      }
    };

    const addPriceToItem = (item) => {
      const price = prices.find((p) => p.itemNo === item.number);
      return {
        ...item,
        customerPrice:
          price !== undefined ? applyCustomerTierRate(price.unitPrice) : 0,
        customerUnitMeasure: price !== undefined ? price.unitMeasure : '',
      };
    };
    const items = await data?.map((item) => addPriceToItem(item));

    await dispatch({
      type: BC_LIST_ITEMS_SUCCESS,
      payload: items,
    });
  } catch (err) {
    dispatch({
      type: BC_LIST_ITEMS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const bcSalesQuote = (order) => async (dispatch, getState) => {
  try {
    dispatch({
      type: BC_SALES_QUOTE_REQUEST,
    });

    // console.log('order', order);

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
      proxy: {
        host: 'localhost',
        port: 5000,
      },
    };

    const {
      bcItemsList: { screenKits, roofKits },
    } = getState();

    const { screens, roofs } = order;
    const mappedScrKits =
      !_.isEmpty(screens) &&
      screens.map((screen) => {
        return scrKitMapper(screen, screenKits);
      });
    const mappedRfKits =
      !_.isEmpty(roofs) &&
      roofs.map((roof) => {
        return rfKitMapper(roof, roofKits);
      });
    const kittedOrder = {
      ...order,
      screens: mappedScrKits ? mappedScrKits : [],
      roofs: mappedRfKits ? mappedRfKits : [],
    };

    // console.log('kittedOrder', kittedOrder);

    const { data } = await toast.promise(
      axios.post('/api/bc/salesQuoteInit', kittedOrder, config),
      {
        pending: 'Sales Quote pending...',
        success: {
          render({ data }) {
            return `Sales Quote: ${data.data.number} created!`;
          },
        },
        error: 'Sales Quote not created.',
      }
    );

    const quotedOrder = {
      orderId: order.orderId,
      quoteId: data.number,
    };

    const { data: updatedOrder } = await axios.post(
      `/api/orders/quotes`,
      quotedOrder,
      config
    );

    // console.log(data, updatedOrder);

    await dispatch({
      type: BC_SALES_QUOTE_SUCCESS,
      payload: [data, updatedOrder],
    });
  } catch (err) {
    dispatch({
      type: BC_SALES_QUOTE_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};
