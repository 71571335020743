import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { StoreContext } from '../context';
import { removeFromCart, clearCart, addOrderToCart } from '../actions';
import { cartSubTotal, cartTotal } from '../utils/storeCalcs';
import { numberFormat } from '../utils/numberFormatter';

import { Message, Loader, CartButtons, CartButtonsAdmin } from './';

import {
  makeStyles,
  Table,
  TableHead,
  TableContainer,
  Typography,
  TableRow,
  TableCell,
  TableBody,
  Input,
  Link,
  Card,
  CardContent,
  CardActions,
  IconButton,
  Collapse,
  Box,
  FormControl,
  TextField,
} from '@material-ui/core';
import {
  DeleteForever,
  Edit,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  input: {
    '&::placeholder': {
      color: 'red',
    },
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    minWidth: 450,
  },
  table: {
    marginBottom: 10,
  },
  clearBtn: {
    marginLeft: 'auto',
    marginRight: 5,
  },
  btns: {
    display: 'flex',
    flexDirection: 'row',
  },
  card: {
    borderRadius: 6,
    minWidth: 450,
  },
  cardMedia: {
    height: 140,
  },
  cardPrice: {
    marginRight: 'auto',
  },
  cardButtons: {
    marginLeft: 'auto',
  },
  cardActions: {
    marginTop: 'auto',
  },
  edited: {
    fontWeight: 'bold',
  },
}));

const ScrDetails = ({ scr, ueScr }) => {
  const { options: opts, measurements: ments, meshOnly, meshOnlyReason } = scr;
  const {
    options: ueopts,
    measurements: uements,
    meshOnly: ueMeshOnly,
    meshOnlyReason: ueMeshOnlyReason,
  } = ueScr;

  const classes = useStyles();

  const fracToDec = (f) => {
    return Number(f) !== 0 ? parseFloat(f.split('/')[0] / f.split('/')[1]) : 0;
  };

  return (
    <Table size='small' key={scr.screenId}>
      <TableBody>
        <TableRow>
          <TableCell
            className={
              opts?.screenType === ueopts?.screenType ? '' : classes.edited
            }
          >
            Housing Size:
          </TableCell>
          <TableCell
            colSpan={4}
            className={
              opts?.screenType === ueopts?.screenType ? '' : classes.edited
            }
          >
            {opts?.screenType}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            className={
              opts?.jobColor === ueopts?.jobColor ? '' : classes.edited
            }
          >
            Job Color:
          </TableCell>
          <TableCell
            colSpan={4}
            className={
              opts?.jobColor === ueopts?.jobColor ? '' : classes.edited
            }
          >
            {opts?.jobColor}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={meshOnly === ueMeshOnly ? '' : classes.edited}>
            Mesh Only?
          </TableCell>
          <TableCell
            colSpan={4}
            className={meshOnly === ueMeshOnly ? '' : classes.edited}
          >
            {meshOnly ? 'Yes' : 'No'}
          </TableCell>
        </TableRow>
        {meshOnly && (
          <TableRow>
            <TableCell
              className={
                meshOnlyReason === ueMeshOnlyReason ? '' : classes.edited
              }
            >
              Mesh Only Reason:
            </TableCell>
            <TableCell
              colSpan={4}
              className={
                meshOnlyReason === ueMeshOnlyReason ? '' : classes.edited
              }
            >
              {meshOnlyReason}
            </TableCell>
          </TableRow>
        )}
        <TableRow>
          <TableCell
            className={
              opts?.meshType === ueopts?.meshType ? '' : classes.edited
            }
          >
            Mesh Type:
          </TableCell>
          <TableCell
            colSpan={4}
            className={
              opts?.meshType === ueopts?.meshType ? '' : classes.edited
            }
          >
            {opts?.meshType}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            className={
              opts?.meshColor === ueopts?.meshColor ? '' : classes.edited
            }
          >
            Mesh Color:
          </TableCell>
          <TableCell
            colSpan={4}
            className={
              opts?.meshColor === ueopts?.meshColor ? '' : classes.edited
            }
          >
            {opts?.meshColor}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            className={opts?.motor === ueopts?.motor ? '' : classes.edited}
          >
            Motor Type:
          </TableCell>
          <TableCell
            colSpan={4}
            className={opts?.motor === ueopts?.motor ? '' : classes.edited}
          >
            {opts?.motor}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            className={
              opts?.motorSide === ueopts?.motorSide ? '' : classes.edited
            }
          >
            Motor Side:
          </TableCell>
          <TableCell
            colSpan={4}
            className={
              opts?.motorSide === ueopts?.motorSide ? '' : classes.edited
            }
          >
            {opts?.motorSide}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            className={opts?.tracks === ueopts?.tracks ? '' : classes.edited}
          >
            Track Type:
          </TableCell>
          <TableCell
            colSpan={4}
            className={opts?.tracks === ueopts?.tracks ? '' : classes.edited}
          >
            {opts?.tracks}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            className={
              opts?.barFinish === ueopts?.barFinish ? '' : classes.edited
            }
          >
            Bottom Bar Finish:
          </TableCell>
          <TableCell
            colSpan={4}
            className={
              opts?.barFinish === ueopts?.barFinish ? '' : classes.edited
            }
          >
            {opts?.barFinish}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            className={
              opts?.mountingAngle === ueopts?.mountingAngle
                ? ''
                : classes.edited
            }
          >
            Mounting Angle:
          </TableCell>
          <TableCell
            colSpan={4}
            className={
              opts?.mountingAngle === ueopts?.mountingAngle
                ? ''
                : classes.edited
            }
          >
            {opts?.mountingAngle ? 'Yes' : 'No'}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            className={
              ments?.widthDisplay === uements?.widthDisplay
                ? ''
                : classes.edited
            }
          >
            Width:
          </TableCell>
          <TableCell
            colSpan={4}
            className={
              ments?.widthDisplay === uements?.widthDisplay
                ? ''
                : classes.edited
            }
          >
            {Number(ments?.width) + fracToDec(ments?.widthFraction)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            className={
              ments?.dropLeftDisplay === uements?.dropLeftDisplay
                ? ''
                : classes.edited
            }
          >
            Drop Left:
          </TableCell>
          <TableCell
            colSpan={4}
            className={
              ments?.dropLeftDisplay === uements?.dropLeftDisplay
                ? ''
                : classes.edited
            }
          >
            {Number(ments?.dropLeft) + fracToDec(ments?.dropLeftFraction)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            className={
              ments?.dropRightDisplay === uements?.dropRightDisplay
                ? ''
                : classes.edited
            }
          >
            Drop Right:
          </TableCell>
          <TableCell
            colSpan={4}
            className={
              ments?.dropRightDisplay === uements?.dropRightDisplay
                ? ''
                : classes.edited
            }
          >
            {Number(ments?.dropRight) + fracToDec(ments?.dropRightFraction)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            className={
              scr?.details === '' || ueScr?.details === ''
                ? ''
                : scr.details === ueScr.details
                ? ''
                : classes.edited
            }
          >
            Additional Details:
          </TableCell>
          <TableCell
            colSpan={4}
            className={
              scr?.details === '' || ueScr?.details === ''
                ? ''
                : scr.details === ueScr.details
                ? ''
                : classes.edited
            }
          >
            {scr?.details}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
const RfDetails = ({ rf, ueRf }) => {
  const {
    colors: clrs,
    frames,
    beamMs,
    beamAs,
    beamXs,
    blades,
    posts,
    addParts,
  } = rf;
  const {
    colors: ueclrs,
    // accessories: ueaccs,
    // posts: ueposts,
  } = ueRf;

  const classes = useStyles();

  return (
    <Table size='small' key={rf.roofId}>
      <TableBody>
        <TableRow>
          <TableCell
            className={
              clrs?.frameColor === ueclrs?.frameColor ? '' : classes.edited
            }
          >
            Frame Color:
          </TableCell>
          <TableCell
            colSpan={4}
            className={
              clrs?.frameColor === ueclrs?.frameColor ? '' : classes.edited
            }
          >
            {clrs?.frameColor}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            className={
              clrs?.bladeColor === ueclrs?.bladeColor ? '' : classes.edited
            }
          >
            Blade Color:
          </TableCell>
          <TableCell
            colSpan={4}
            className={
              clrs?.bladeColor === ueclrs?.bladeColor ? '' : classes.edited
            }
          >
            {clrs?.bladeColor}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            className={
              clrs?.postColor === ueclrs?.postColor ? '' : classes.edited
            }
          >
            Post Color:
          </TableCell>
          <TableCell
            colSpan={4}
            className={
              clrs?.postColor === ueclrs?.postColor ? '' : classes.edited
            }
          >
            {clrs?.postColor}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            className={rf?.motorType === ueRf?.motorType ? '' : classes.edited}
          >
            {`Motor (${rf?.motorType})`}
          </TableCell>
          <TableCell
            colSpan={4}
            className={
              rf?.motorQuantity === ueRf?.motorQuantity ? '' : classes.edited
            }
          >
            {rf?.motorQuantity}
          </TableCell>
        </TableRow>
        {frames?.length > 0 &&
          frames.map((frame, i) => (
            <TableRow key={i}>
              <TableCell>{`Perimeter Frame (${frame?.frameType})`}</TableCell>
              <TableCell
                colSpan={4}
              >{`${frame?.frameFeet}'${frame?.frameInches}"`}</TableCell>
            </TableRow>
          ))}
        {beamMs?.length > 0 &&
          beamMs.map((beam, i) => (
            <TableRow key={i}>
              <TableCell>{`Midspan Beam (${beam?.beamType})`}</TableCell>
              <TableCell
                colSpan={4}
              >{`${beam?.beamFeet}'${beam?.beamInches}"`}</TableCell>
            </TableRow>
          ))}
        {beamAs?.length > 0 &&
          beamAs.map((beam, i) => (
            <TableRow key={i}>
              <TableCell>{`Accessory Beam (${beam?.beamType})`}</TableCell>
              <TableCell
                colSpan={4}
              >{`${beam?.beamFeet}'${beam?.beamInches}"`}</TableCell>
            </TableRow>
          ))}
        {beamXs?.length > 0 &&
          beamXs.map((beam, i) => (
            <TableRow key={i}>
              <TableCell>{`Extra (${beam?.beamType})`}</TableCell>
              <TableCell
                colSpan={4}
              >{`${beam?.beamFeet}'${beam?.beamInches}"`}</TableCell>
            </TableRow>
          ))}
        {blades?.length > 0 &&
          blades.map((blade, i) => (
            <TableRow key={i}>
              <TableCell>{`Blade (${blade?.bladeType})`}</TableCell>
              <TableCell
                colSpan={4}
              >{`${blade?.bladeQty} x ${blade?.bladeFeet}'${blade?.bladeInches}"`}</TableCell>
            </TableRow>
          ))}
        {posts?.length > 0 &&
          posts.map((post, i) => (
            <TableRow key={i}>
              <TableCell>{`Post (${post?.postStyle})`}</TableCell>
              <TableCell colSpan={4}>{`${post?.postLength}${
                post?.postLength === '19' ? '\'4"' : "'"
              }`}</TableCell>
            </TableRow>
          ))}
        {addParts?.length > 0 &&
          addParts.map((part, i) => (
            <TableRow key={i}>
              <TableCell>{`Addt'l Part (${part?.partName})`}</TableCell>
              <TableCell colSpan={4}>{`${part?.partQty}`}</TableCell>
            </TableRow>
          ))}
        <TableRow>
          <TableCell
            className={
              rf?.details === '' || ueRf?.details === ''
                ? ''
                : rf.details === ueRf.details
                ? ''
                : classes.edited
            }
          >
            Additional Details:
          </TableCell>
          <TableCell
            colSpan={4}
            className={
              rf?.details === '' || ueRf?.details === ''
                ? ''
                : rf.details === ueRf.details
                ? ''
                : classes.edited
            }
          >
            {rf?.details}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

const CartLineButtons = ({ itemType, item, status }) => {
  const dispatch = useDispatch();
  const ctx = useContext(StoreContext);

  const setScrHandler = (e, scr) => {
    e.preventDefault();
    ctx.setScr({
      ...ctx.scr,
      screenName: scr.screenName,
      screenId: scr.screenId,
      options: scr.options,
      measurements: scr.measurements,
      meshOnly: scr.meshOnly,
      meshOnlyReason: scr.meshOnlyReason,
      price: scr.price,
      details: scr.details,
    });
  };
  const setRfHandler = (e, rf) => {
    e.preventDefault();
    ctx.setRf({
      ...ctx.rf,
      roofName: rf.roofName,
      roofId: rf.roofId,
      measurements: rf.measurements,
      colors: rf.colors,
      motorType: rf.motorType,
      motorQuantity: rf.motorQuantity,
      frames: rf.frames,
      beamMs: rf.beamMs,
      beamAs: rf.beamAs,
      beamXs: rf.beamXs,
      blades: rf.blades,
      posts: rf.posts,
      addParts: rf.addParts,
      price: rf.price,
      details: rf.details,
      file: rf.file,
      fileName: rf.fileName,
    });
  };
  const removeHandler = (e, type, id) => {
    e.preventDefault();
    dispatch(removeFromCart(type, id));
    ctx.setScr(ctx.scr);
    ctx.setRf(ctx.rf);
  };

  switch (itemType) {
    case 'scr':
      if (ctx.isAdmin && (status === 'Submitted' || status === 'Approved')) {
        return (
          <Box display='flex' justifyContent='space-evenly'>
            <Link
              size='small'
              color='textSecondary'
              component='button'
              variant='body2'
              onClick={(e) => setScrHandler(e, item)}
            >
              <Edit fontSize='small' />
            </Link>
            <Link
              onClick={(e) => removeHandler(e, 's', item.screenId)}
              size='small'
              color='error'
              component='button'
              variant='body2'
            >
              <DeleteForever fontSize='small' />
            </Link>
          </Box>
        );
      } else if (status === 'In Cart' || status === 'Draft') {
        return (
          <Box display='flex' justifyContent='space-evenly'>
            <Link
              size='small'
              color='textSecondary'
              component='button'
              variant='body2'
              onClick={(e) => setScrHandler(e, item)}
            >
              <Edit fontSize='small' />
            </Link>
            <Link
              onClick={(e) => removeHandler(e, 's', item.screenId)}
              size='small'
              color='error'
              component='button'
              variant='body2'
            >
              <DeleteForever fontSize='small' />
            </Link>
          </Box>
        );
      }
      return <Box display='flex' justifyContent='space-evenly'></Box>;

    case 'rf':
      if (ctx.isAdmin && status === 'Submitted') {
        return (
          <Box display='flex' justifyContent='space-evenly'>
            <Link
              size='small'
              color='textSecondary'
              component='button'
              variant='body2'
              onClick={(e) => setRfHandler(e, item)}
            >
              <Edit fontSize='small' />
            </Link>
            <Link
              onClick={(e) => removeHandler(e, 'r', item.roofId)}
              size='small'
              color='error'
              component='button'
              variant='body2'
            >
              <DeleteForever fontSize='small' />
            </Link>
          </Box>
        );
      } else if (status === 'In Cart' || status === 'Draft') {
        return (
          <Box display='flex' justifyContent='space-evenly'>
            <Link
              size='small'
              color='textSecondary'
              component='button'
              variant='body2'
              onClick={(e) => setRfHandler(e, item)}
            >
              <Edit fontSize='small' />
            </Link>
            <Link
              onClick={(e) => removeHandler(e, 'r', item.roofId)}
              size='small'
              color='error'
              component='button'
              variant='body2'
            >
              <DeleteForever fontSize='small' />
            </Link>
          </Box>
        );
      }
      return <Box display='flex' justifyContent='space-evenly'></Box>;

    case 'part':
      if (ctx.isAdmin && status === 'Submitted') {
        return (
          <Link
            onClick={(e) => removeHandler(e, 'p', item.id)}
            size='small'
            color='error'
            component='button'
            variant='body2'
          >
            <DeleteForever fontSize='small' />
          </Link>
        );
        // }
      } else if (status === 'In Cart' || status === 'Draft') {
        return (
          <Link
            onClick={(e) => removeHandler(e, 'p', item.id)}
            size='small'
            color='error'
            component='button'
            variant='body2'
          >
            <DeleteForever fontSize='small' />
          </Link>
        );
      }
      return '';

    default:
      return '';
  }
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Cart = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const query = useQuery();
  const ctx = useContext(StoreContext);
  const history = useHistory();

  const { isAdmin } = ctx;

  const createdOrder = useSelector((state) => state.orderCreate);
  const { loading, error, status } = createdOrder;

  const orderId = query.get('order');

  const [selectedIndex, setSelectedIndex] = useState('');

  const handleClick = (index) => {
    if (selectedIndex === index) {
      setSelectedIndex('');
    } else {
      setSelectedIndex(index);
    }
  };

  let screens;
  let roofs;
  let parts;

  screens = ctx?.cartInfo?.screens?.sort((a, b) =>
    a.screenName > b.screenName ? 1 : -1
  );
  roofs = ctx?.cartInfo?.roofs?.sort((a, b) =>
    a.roofName > b.roofName ? 1 : -1
  );
  parts = ctx?.cartInfo?.parts?.sort((a, b) =>
    a.displayName > b.displayName ? 1 : -1
  );

  useEffect(() => {
    if (orderId?.length > 0) {
      dispatch(addOrderToCart(orderId));
    }
    if (status && status === 201) {
      let dest = isAdmin ? '/admin' : '/account';
      dispatch(clearCart());
      history.push(dest);
      window.location.reload();
    }
  }, [orderId, dispatch, status, isAdmin, history]);

  let scrIndex;
  const calcScrIndex = (scr) => {
    scrIndex = scr.screenId ? scr.screenId : scr._id;
    return scrIndex;
  };
  let rfIndex;
  const calcRfIndex = (rf) => {
    rfIndex = rf.roofId ? rf.roofId : rf._id;
    return rfIndex;
  };

  const getUneditedScr = (scr) => {
    if (!_.isEmpty(ctx?.cartInfo)) {
      const { screens: oScrs } = ctx.cartInfo
        ? ctx.cartInfo
        : ctx.cartInfo.original;
      const ueScr =
        oScrs?.length > 0
          ? oScrs.filter(
              (e) => e.screenId?.length > 0 && e.screenId === scr.screenId
            )
          : [{}];
      return ueScr[0];
    }
  };
  const getUneditedRf = (rf) => {
    if (!_.isEmpty(ctx?.cartInfo)) {
      const { roofs: oRfs } = ctx.cartInfo
        ? ctx.cartInfo
        : ctx.cartInfo.original;
      const ueRf =
        oRfs?.length > 0
          ? oRfs.filter((e) => e.roofId?.length > 0 && e.roofId === rf.roofId)
          : [{}];
      return ueRf[0];
    }
  };

  const partQtyDisabled = () => {
    let disable = false;
    if (ctx.isAdmin) {
      disable = ctx.cartInfo.status === 'Shipped';
    } else {
      disable =
        ctx.cartInfo.status !== 'In Cart' && ctx.cartInfo.status !== 'Draft';
    }
    return disable;
  };

  return loading ? (
    <Loader />
  ) : error ? (
    <Message severity='error'>{error}</Message>
  ) : (
    <Card className={classes.card}>
      {ctx.isAdmin && <CartButtonsAdmin />}
      <CardContent>
        <Typography gutterBottom color='textPrimary'>
          Retractable Screens
        </Typography>
        <TableContainer>
          <Table className={classes.table} size='small'>
            <TableHead>
              <TableRow>
                <TableCell align='center'></TableCell>
                <TableCell align='left'>Screen</TableCell>
                <TableCell align='right'>Price</TableCell>
                <TableCell align='right' colSpan={2}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {screens?.length > 0 ? (
                screens.map((scr) => (
                  <Fragment key={calcScrIndex(scr)}>
                    <TableRow>
                      <TableCell align='center'>
                        <IconButton
                          size='small'
                          onClick={() => handleClick(calcScrIndex(scr))}
                        >
                          {calcScrIndex(scr) === selectedIndex ? (
                            <KeyboardArrowUp />
                          ) : (
                            <KeyboardArrowDown />
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell
                        align='left'
                        className={
                          getUneditedScr(scr).screenName === undefined
                            ? ''
                            : scr === getUneditedScr(scr)
                            ? ''
                            : classes.edited
                        }
                      >
                        {scr.screenName}
                      </TableCell>
                      <TableCell
                        align='right'
                        className={
                          getUneditedScr(scr).price === undefined
                            ? ''
                            : scr.price === getUneditedScr(scr).price
                            ? ''
                            : classes.edited
                        }
                      >
                        {numberFormat('cur-display', scr.price)}
                      </TableCell>
                      <TableCell colSpan={2} align='center'>
                        <CartLineButtons
                          itemType={'scr'}
                          item={scr}
                          status={ctx.cartInfo.status}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={5}
                      >
                        <Collapse
                          in={scrIndex === selectedIndex}
                          timeout='auto'
                          unmountOnExit
                        >
                          <ScrDetails scr={scr} ueScr={getUneditedScr(scr)} />
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </Fragment>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align='center'>
                    <h3>No screens added</h3>
                  </TableCell>
                </TableRow>
              )}
              {screens !== undefined && (
                <TableRow>
                  <TableCell colSpan={3} align='right'>
                    Screens Subtotal:
                  </TableCell>
                  <TableCell align='right'>
                    {ctx.cartInfo?.screens?.length > 0
                      ? numberFormat(
                          'cur-display',
                          cartSubTotal(ctx.cartInfo).screens.screensTotal
                        )
                      : numberFormat('cur-display', 0)}
                  </TableCell>
                  <TableCell />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Typography gutterBottom color='textPrimary'>
          Louvered Roofs
        </Typography>
        <TableContainer>
          <Table className={classes.table} size='small'>
            <TableHead>
              <TableRow>
                <TableCell align='center'></TableCell>
                <TableCell align='left'>Roof</TableCell>
                <TableCell align='right'>Price</TableCell>
                <TableCell align='right' colSpan={2}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {roofs?.length > 0 ? (
                roofs.map((rf) => (
                  <Fragment key={calcRfIndex(rf)}>
                    <TableRow>
                      <TableCell align='center'>
                        <IconButton
                          size='small'
                          onClick={() => handleClick(calcRfIndex(rf))}
                        >
                          {calcRfIndex(rf) === selectedIndex ? (
                            <KeyboardArrowUp />
                          ) : (
                            <KeyboardArrowDown />
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell
                        align='left'
                        className={
                          getUneditedRf(rf).roofName === undefined
                            ? ''
                            : rf === getUneditedRf(rf)
                            ? ''
                            : classes.edited
                        }
                      >
                        {rf.roofName}
                      </TableCell>
                      <TableCell
                        align='right'
                        className={
                          getUneditedRf(rf).price === undefined
                            ? ''
                            : rf.price === getUneditedRf(rf).price
                            ? ''
                            : classes.edited
                        }
                      >
                        {numberFormat('cur-display', rf.price)}
                      </TableCell>
                      <TableCell colSpan={2} align='center'>
                        <CartLineButtons
                          itemType={'rf'}
                          item={rf}
                          status={ctx.cartInfo.status}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={5}
                      >
                        <Collapse
                          in={rfIndex === selectedIndex}
                          timeout='auto'
                          unmountOnExit
                        >
                          <RfDetails rf={rf} ueRf={getUneditedRf(rf)} />
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </Fragment>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align='center'>
                    <h3>No roofs added</h3>
                  </TableCell>
                </TableRow>
              )}
              {roofs !== undefined && (
                <TableRow>
                  <TableCell colSpan={3} align='right'>
                    Roofs Subtotal:
                  </TableCell>
                  <TableCell align='right'>
                    {ctx.cartInfo?.roofs?.length > 0
                      ? numberFormat(
                          'cur-display',
                          cartSubTotal(ctx.cartInfo).roofs.roofsTotal
                        )
                      : numberFormat('cur-display', 0)}
                  </TableCell>
                  <TableCell />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Typography gutterBottom color='textPrimary'>
          Parts
        </Typography>
        <TableContainer>
          <Table className={classes.table} size='small'>
            <TableHead>
              <TableRow>
                <TableCell align='left'>Part</TableCell>
                <TableCell align='right'>Quantity</TableCell>
                <TableCell align='right'>Price</TableCell>
                <TableCell align='right'>Line</TableCell>
                <TableCell align='right'></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {parts?.length > 0 ? (
                parts.map((part) => (
                  <TableRow key={part.id}>
                    <TableCell align='left'>{part.displayName}</TableCell>
                    <TableCell>
                      <Input
                        inputProps={{
                          style: { textAlign: 'right' },
                          size: 3,
                          min: 1,
                        }}
                        disabled={partQtyDisabled()}
                        type={'number'}
                        size='small'
                        value={part.quantity}
                        onChange={(e) =>
                          ctx.handleAddPrtToCart(
                            e,
                            part.id,
                            part.number,
                            part.displayName,
                            e.target.value,
                            part.price
                          )
                        }
                      ></Input>
                    </TableCell>
                    <TableCell align='right'>
                      {numberFormat('cur-display', part.price)}
                    </TableCell>
                    <TableCell align='right'>
                      {numberFormat('cur-display', part.price * part.quantity)}
                    </TableCell>
                    <TableCell>
                      <CartLineButtons
                        itemType={'part'}
                        item={part}
                        status={ctx.cartInfo.status}
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align='center'>
                    <h3>No parts added</h3>
                  </TableCell>
                </TableRow>
              )}
              {parts !== undefined && (
                <>
                  {parts.length > 0 && (
                    <TableRow>
                      <TableCell colSpan={5}>
                        <FormControl required fullWidth>
                          <TextField
                            fullWidth
                            multiline
                            placeholder='Enter any parts instructions here'
                            value={ctx.extraDetails}
                            type='text'
                            variant='outlined'
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                            onChange={(e) =>
                              ctx.setExtraDetails(e.target.value)
                            }
                          />
                        </FormControl>
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell colSpan={3} align='right'>
                      Parts Subtotal:
                    </TableCell>
                    <TableCell align='right'>
                      {ctx.cartInfo?.parts?.length > 0
                        ? numberFormat(
                            'cur-display',
                            cartSubTotal(ctx.cartInfo).parts.partsTotal
                          )
                        : numberFormat('cur-display', 0)}
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Typography className={classes.cardPrice}>
          <strong>Cart Total: </strong>
          {numberFormat('cur-display', cartTotal(ctx.cartInfo).totalPrice)}
        </Typography>
        <CartButtons />
      </CardActions>
      <Box display='flex'>
        <Typography
          variant='caption'
          style={{
            marginLeft: 'auto',
            marginRight: 15,
            marginBottom: 5,
          }}
          color='error'
          align='right'
        >
          Taxes and shipping not included
        </Typography>
      </Box>
    </Card>
  );
};

export { Cart };
